import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/pages/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/pages/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/pages/login')
             return Promise.reject(error)
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/pages/login')
           return Promise.reject(error)
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIAdminConnected {
  // ------------------------ Handle Users -----------------

  getAllInactiveCollaborateurs (token) {
    const url = apiBaseUrl + '/inactive-collaborateurs'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers})
  }

  createNewUser (token, username, password, confirm_password, first_name, last_name,
    sex, fonction, taux_horaire, birthdate, phone_country_code, phone_number,
    is_manager, is_admin, is_boss) {
    const url = apiBaseUrl + '/create-collaborateur'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'username': username,
        'password': password,
        'confirm_password': confirm_password,
        'first_name': first_name,
        'last_name': last_name,
        'sex': sex,
        'fonction': fonction,
        'taux_horaire': taux_horaire,
        'birthdate': birthdate,
        'phone_country_code': phone_country_code,
        'phone_number': phone_number,

        'is_manager': is_manager,
        'is_admin': is_admin,
        'is_boss': is_boss

      },
      {
        'headers': headers,
      }
    )
  }

  addUserToManagerGroup(token, id) {
    const url = apiBaseUrl + `/add-user-to-manager-group/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  removeUserFromManagerGroup(token, id) {
    const url = apiBaseUrl + `/remove-user-from-manager-group/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  addUserToAdminGroup(token, id) {
    const url = apiBaseUrl + `/add-user-to-admin-group/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  removeUserFromAdminGroup(token, id) {
    const url = apiBaseUrl + `/remove-user-from-admin-group/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  addUserToBossGroup(token, id) {
    const url = apiBaseUrl + `/add-user-to-boss-group/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  removeUserFromBossGroup(token, id) {
    const url = apiBaseUrl + `/remove-user-from-boss-group/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  desactivateUser(token, id, inactive_email) {
    const url = apiBaseUrl + `/desactivate-user/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'inactive_email': inactive_email
    }
    return axios_instance.put(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  activateUser(token, id) {
    const url = apiBaseUrl + `/activate-user/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  getCollaborateur(token, id) {
    const url = apiBaseUrl + `/collaborateur/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
      }
    )
  }

  getCollaborateurAllMissions(token, id) {
    const url = apiBaseUrl + `/get-collaborateur-all-missions/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
      }
    )
  }

  modifyCollaborateur(token, id, email, first_name, last_name, sex, fonction, taux_horaire, birthdate, phone_country_code, phone_number) {
    const url = apiBaseUrl + `/collaborateur/` + id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'username': email,
        'sex': sex,
        'first_name': first_name,
        'last_name': last_name,
        'fonction': fonction,
        'taux_horaire': taux_horaire,
        'birthdate': birthdate,
        'phone_country_code': phone_country_code,
        'phone_number': phone_number
      },
      {
        'headers': headers,
      }
    )
  }

  // --------------- Mission Types ------------------

  getAllInactiveMissionTypes(token) {
    const url = apiBaseUrl + '/get-all-inactive-mission-types'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  createMissionType (token, name) {
    const url = apiBaseUrl + '/create-mission-type'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'name': name,
      },
      {
        'headers': headers,
      }
    )
  }

  modifyMissionType (token, mission_type_pk, name) {
    const url = apiBaseUrl + `/mission-type/` + mission_type_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'name': name,
      },
      {
        'headers': headers,
      }
    )
  }

  deleteMissionType (token, mission_type_pk) {
    const url = apiBaseUrl + `/mission-type/` + mission_type_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  activateMissionType (token, mission_type_pk) {
    const url = apiBaseUrl + `/activate-mission-type/` + mission_type_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {},
      {
        'headers': headers,
      }
    )
  }
}
