<template>
  <div class="CollaborateurDetails">
    <CModal
      :show.sync="isCollaborateurModified"
      :no-close-on-backdrop="true"
      title="Collaborateur modifiée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre collaborateur a bien été modifié 👍
      </p>
      <p>
        Vous pouvez continuer les modifications ou retourner à la liste des collaborateurs du cabinet.
      </p>
      <template #footer>
        <CButton @click="isCollaborateurModified = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/administration/manage-collaborateurs/')" color="dark">Retour à la liste des collaborateurs</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isCollaborateurArchiving"
      :no-close-on-backdrop="true"
      title="Collaborateur archivé"
      size="lg"
      color="dark"
    >
      <p class="text-danger">
        <strong>Si vous archivez ce collaborateur, celui-ci ne pourra plus se connecter à son compte et ne pourra plus être affecté sur des missions.</strong>
      </p>
      <p>
        Pour respecter les règles de déontologie, vous pouvez renseigner ici la nouvelle adresse mail de votre collaborateur.
        <em> Nous vons conseillons également de mettre en place un système de redirection automatique de mails pour vos clients.</em>
      </p>

      <CInput
        class="mt-2"
        label="Email (facultatif)"
        type="email"
        maxlength="150"
        placeholder="Email personnel ou nouveau mail professionnel"
        v-model="desactivateCollaborateurEmail"
        @input="$v.desactivateCollaborateurEmail.$touch()"
        :isValid="$v.desactivateCollaborateurEmail.$dirty ? !$v.desactivateCollaborateurEmail.$error : null"
      />

      <CRow class="mt-2">
        <CCol class="text-center">
          <CButton
            :color="desactivateUserButtonStyle"
            shape="pill" block class="px-4"
            @click='desactivateUser'
            :disabled="userDesactivatingInProcess">
            <CSpinner size="sm" label="remove user spinner" v-if="userDesactivatingInProcess"></CSpinner>
            {{ desactivateUserButtonText }}
          </CButton>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="isCollaborateurArchiving = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <CModal
      :show.sync="isCollaborateurArchived"
      :no-close-on-backdrop="true"
      title="Collaborateur archivé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        ⛔ Votre collaborateur a bien été archivé ⛔
      </p>
      <p>
        Il ne pourra plus se connecter à son espace personnel et ne sera plus disponible pour être affecté sur des missions.
      </p>
      <template #footer>
        <CButton @click="$router.push('/administration/manage-collaborateurs/')" color="primary">Retour à la liste des collaborateurs</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>
    
    <CRow>
      <CCol>
        <h1> {{collaborateur.user.first_name}} {{collaborateur.user.last_name}} </h1>
      </CCol>
    </CRow>
    
    <CCard class="mt-3">
      <CCardHeader>
        <strong>Informations générales</strong>
      </CCardHeader>
      <CCardBody>
        <CInput
          label="Email du collaborateur"
          type="email"
          maxlength="150"
          placeholder="Email"
          :disabled="!collaborateur.user.is_active"
          v-model="modifyCollaborateurEmail"
          @input="$v.modifyCollaborateurEmail.$touch(); isExistingModifyUserEmail = false"
          :isValid="$v.modifyCollaborateurEmail.$dirty ? !$v.modifyCollaborateurEmail.$error : null"
        >
          <template #description>
            <small v-if="isExistingModifyUserEmail" class="text-danger">
              {{ modifyCollaborateurEmail }} existe déjà. Veuillez choisir un autre nom d'utilisateur
            </small>
          </template>
        </CInput>
        <CRow>
          <CCol sm="4">
            <CSelect
              :value.sync="modifyCollaborateurSex"
              label="Civilité"
              :disabled="!collaborateur.user.is_active"
              @input="$v.modifyCollaborateurSex.$touch()"
              :isValid="$v.modifyCollaborateurSex.$dirty ? !$v.modifyCollaborateurSex.$error : null"
              :options='userSexOptions'
              custom
            />
          </CCol>
          <CCol sm="4">
            <CInput
              label="Prénom"
              v-model="modifyCollaborateurFirstName" type="text"
              maxlength="30"
              :disabled="!collaborateur.user.is_active"
              @input="$v.modifyCollaborateurFirstName.$touch()"
              :isValid="$v.modifyCollaborateurFirstName.$dirty ? !$v.modifyCollaborateurFirstName.$error : null"
              invalid-feedback="Le prénom doit comporter entre 2 et 30 caractères"
            >
            </CInput>
          </CCol>
          <CCol sm="4">
            <CInput
              label="Nom"
              v-model="modifyCollaborateurLastName" type="text"
              maxlength="150"
              :disabled="!collaborateur.user.is_active"
              @input="$v.modifyCollaborateurLastName.$touch()"
              :isValid="$v.modifyCollaborateurLastName.$dirty ? !$v.modifyCollaborateurLastName.$error : null"
              invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
            >
            </CInput>
          </CCol>
        </CRow>

        <CRow>
          <CCol sm="6">
            <CSelect
              :value.sync="modifyCollaborateurFonction"
              label="Fonction"
              :disabled="!collaborateur.user.is_active"
              @input="$v.modifyCollaborateurFonction.$touch()"
              :isValid="$v.modifyCollaborateurFonction.$dirty ? !$v.modifyCollaborateurFonction.$error : null"
              :options='userFonctionOptions'
              custom
            />
          </CCol>
          <CCol sm="6">
            <CInput
              label="Taux horaire (en € HT)"
              v-model="modifyCollaborateurTauxHoraire" type="number"
              :disabled="!collaborateur.user.is_active"
              placeholder="Taux horaire indicatif de votre collaborateur"
              @input="$v.modifyCollaborateurTauxHoraire.$touch()"
              :isValid="$v.modifyCollaborateurTauxHoraire.$dirty ? !$v.modifyCollaborateurTauxHoraire.$error : null"
            />
          </CCol>
        </CRow>

        <CRow>
          <CCol md="6">

            <label> Votre date de naissance </label>
            <CRow>
              <CCol sm="3">
                <CSelect
                  :value.sync="modifyCollaborateurBirthdateDay"
                  :options='daysArrayForm'
                  custom
                />
              </CCol>
              <CCol sm="6">
                <CSelect
                  :value.sync="modifyCollaborateurBirthdateMonth"
                  :options='monthsArrayForm'
                  custom
                />
              </CCol>
              <CCol sm="3">
                <CSelect
                  :value.sync="modifyCollaborateurBirthdateYear"
                  :options='yearsArrayForm'
                  custom
                />
              </CCol>
            </CRow>
            <small class="text-danger" v-if="$v.birthdateFormat.$invalid ">Veuillez saisir une date valide</small>

          </CCol>
          <CCol md="6">
            <div role="group" class="form-group">
              <label for="modifyCollaborateurVuePhoneNumber" class>
                Numéro de téléphone
              </label>
              <vue-phone-number-input
                id="modifyCollaborateurVuePhoneNumber"
                name="modifyCollaborateurVuePhoneNumber"
                v-model="modifyCollaborateurPhoneNumber"
                :default-country-code='modifyCollaborateurPhoneNumberResult.countryCode'
                :preferred-countries="['FR', 'BE', 'DE']"
                valid-color='#28a745'
                error-color='#dc3545'
                :translations="{
                  countrySelectorLabel: 'Code pays',
                  countrySelectorError: 'Choisir un pays',
                  phoneNumberLabel: 'Numéro de téléphone',
                  example: 'Exemple :'
                }"
                @update='modifyCollaborateurPhoneNumberResult = $event'
                :required='false'
              />
              <small class="text-danger" v-if="modifyCollaborateurPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <div v-if="collaborateur.user.is_active">
      
      <CCard>
        <CCardHeader>
          <strong>Rôles de votre collaborateur </strong>
            <CBadge color="dark">Collaborateur</CBadge>
            <CBadge class="ml-1" color="danger" v-if="!userGroupNotContain('MANAGER')">Manager</CBadge>
            <CBadge class="ml-1" color="primary" v-if="!userGroupNotContain('ADMIN')">Administrateur</CBadge>
            <CBadge class="ml-1" color="success" v-if="!userGroupNotContain('BOSS')">Super-administrateur</CBadge>
        </CCardHeader>
        <CCardBody>

        
          <CRow>
            <CCol sm="6" class="text-center">
              <CButton
                v-if="userGroupNotContain('MANAGER')"
                :color="setUserManagerButtonStyle"
                shape="pill" block class="px-2" size="sm"
                @click='setUserManagerStatus'
                :disabled="userManagerSettingInProcess">
                <CSpinner size="sm" label="set user manager Spinner" v-if="userManagerSettingInProcess"></CSpinner>
                {{ setUserManagerButtonText }}
              </CButton>
              <CButton
                v-else
                :color="removeUserManagerButtonStyle"
                shape="pill" block class="px-2" size="sm"
                @click='removeUserManagerStatus'
                :disabled="userManagerRemovingInProcess">
                <CSpinner size="sm" label="remove user manager Spinner" v-if="userManagerRemovingInProcess"></CSpinner>
                {{ removeUserManagerButtonText }}
              </CButton>
            </CCol>
            <CCol sm="6" class="text-center mt-3 mt-sm-0">
              <CButton
                v-if="userGroupNotContain('ADMIN')"
                :color="setUserAdminButtonStyle"
                shape="pill" block class="px-2" size="sm"
                @click='setUserAdminStatus'
                :disabled="userAdminSettingInProcess">
                <CSpinner size="sm" label="set user admin Spinner" v-if="userAdminSettingInProcess"></CSpinner>
                {{ setUserAdminButtonText }}
              </CButton>
              <CButton
                v-else
                :color="removeUserAdminButtonStyle"
                shape="pill" block class="px-2" size="sm"
                @click='removeUserAdminStatus'
                :disabled="userAdminRemovingInProcess">
                <CSpinner size="sm" label="remove user admin Spinner" v-if="userAdminRemovingInProcess"></CSpinner>
                {{ removeUserAdminButtonText }}
              </CButton>
            </CCol>
          </CRow>
          
          <CRow class="mt-3" v-if="userGroupContains(['BOSS'])">
            <CCol>
              <CButton
                v-if="userGroupNotContain('BOSS')"
                :color="setUserBossButtonStyle"
                shape="pill" block class="px-2" size="sm"
                @click='setUserBossStatus'
                :disabled="userBossSettingInProcess">
                <CSpinner size="sm" label="set user boss Spinner" v-if="userBossSettingInProcess"></CSpinner>
                {{ setUserBossButtonText }}
              </CButton>
              <CButton
                v-else
                :color="removeUserBossButtonStyle"
                shape="pill" block class="px-2" size="sm"
                @click='removeUserBossStatus'
                :disabled="userBossRemovingInProcess">
                <CSpinner size="sm" label="remove user boss Spinner" v-if="userBossRemovingInProcess"></CSpinner>
                {{ removeUserBossButtonText }}
              </CButton>
            </CCol>
          </CRow>
          
          <CRow class="mt-3">
            <CCol>
              <p>
                ❓ Vous pouvez accéder au détail des rôles en <router-link to='/aide/role' target="_blank">cliquant ici</router-link>
              </p>  
            </CCol>
          </CRow>
        
        </CCardBody>
      </CCard>
      
      <CRow>
        <CCol sm="6">
          <CButton
            :color="modifyCollaborateurButtonStyle"
            shape="pill" block class="px-4"
            @click='modifyCollaborateur'
            :disabled="$v.modifyCollaborateurEmail.$invalid || $v.modifyCollaborateurFirstName.$invalid || $v.modifyCollaborateurLastName.$invalid || $v.modifyCollaborateurSex.$invalid || $v.modifyCollaborateurFonction.$invalid || $v.birthdateFormat.$invalid || $v.modifyCollaborateurPhoneNumberResult.$invalid || $v.modifyCollaborateurTauxHoraire.$invalid || collaborateurModificationInProcess">
            <CSpinner size="sm" label="rodify user spinner" v-if="collaborateurModificationInProcess"></CSpinner>
            {{ modifyCollaborateurButtonText }}
          </CButton>
        </CCol>
        <CCol sm="6" class="mt-2 mt-sm-0">
          <CButton
            color="danger"
            shape="pill" block class="px-4"
            @click='isCollaborateurArchiving = true'>
            Désactivez ce collaborateur
          </CButton>
        </CCol>
      </CRow>
    </div>

    <div v-else>
      <CRow>
        <CCol sm="6">
          <strong> Nouvel email du collaborateur : </strong> <span v-if="desactivateCollaborateurEmail"> {{ desactivateCollaborateurEmail }}</span> <span v-else>Non renseigné</span>
        </CCol>
      </CRow>
      <CRow class="mt-4">
        <CCol class="text-center">
          <CButton
            :color="activateUserButtonStyle"
            shape="pill" block class="px-4"
            @click='activateUser'
            :disabled="userActivationInProcess">
            <CSpinner size="sm" label="activate user spinner" v-if="userActivationInProcess"></CSpinner>
            {{ activateUserButtonText }}
          </CButton>
        </CCol>
      </CRow>
    </div>

    <CCard class="mt-4" >
      <CCardHeader>
        <strong>Missions auxquelles {{collaborateur.user.first_name}} est affecté</strong>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          class="cursor-table"
          headVariant="light"
          hover
          striped
          :items="missions"
          :fields="missionsRenderFields"
          @row-clicked="goToMissionPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #name="{item}">
          <td>
           <span>{{item.name}}</span>
           <div v-if="item.is_paiement_alert">
             <em class="text-danger">Alerte de paiement levée</em>
           </div>
          </td>
        </template>

        <template #types="{item}">
          <td>
            <em v-for="type in item.types" v-bind:key="type.id">{{type.name}} </em>
          </td>
        </template>

        <template #collaborateurs="{item}">
          <td>
            <em v-for="collaborateur in item.collaborateurs" v-bind:key="collaborateur.id">{{collaborateur.full_name}}, </em>
          </td>
        </template>

        <template #description="{item}">
          <td>
            <small> {{item.description}}</small>
          </td>
        </template>

        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, minValue, maxValue, email, integer} from 'vuelidate/lib/validators'
import { isDate } from '@/validators/validators'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { APIAdminConnected } from '@/api/APIAdminConnected'

import userMixins from '@/mixins/userMixins'


const apiAdminConnected = new APIAdminConnected()


export default {
  name: 'CollaborateurDetails',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
    userMixins
  ],
  data: function () {
    return {

      isCollaborateurLoading: true,
      collaborateur: {
        id: '',
        birthdate: '',
        fonction: '',
        full_name: '',
        phone_number: '',
        sex: '',
        taux_horaire: '',
        user: {
          id: '',
          first_name: '',
          last_name: '',
          username: '',
          groups: [],
          is_active: true
        }
      },
      
      isCollaborateurAllMissionsLoading: false,
      missions: [],
      missionsRenderFields: [
        { key: "client_name", label: "Nom du client", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "name", label: "Intitulé mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "types", label: "Type de mission", tdClass: 'ui-helper-center', _style: "min-width: 200px;", sortable: true},
        { key: "referent_full_name", label: "Référent mission", tdClass: 'ui-helper-center', _style: "min-width: 150px;"},
        { key: "collaborateurs", label: "Collaborateurs affectés", tdClass: 'ui-helper-center', _style: "min-width: 200px;" },
        { key: "description", label: "Description", tdClass: 'ui-helper-center', _style: "min-width: 200px;"},
      ],

      // ------------ MODIFY COLLABORATEUR ----------
      isCollaborateurModified: false,

      modifyCollaborateurEmail: '',
      isExistingModifyUserEmail: false,
      modifyCollaborateurFirstName: '',
      modifyCollaborateurLastName: '',
      modifyCollaborateurSex: '',
      modifyCollaborateurFonction: '',
      modifyCollaborateurTauxHoraire: '',
      modifyCollaborateurBirthdateYear: '1975',
      modifyCollaborateurBirthdateMonth: '01',
      modifyCollaborateurBirthdateDay: '01',
      modifyCollaborateurPhoneNumber: null,
      modifyCollaborateurPhoneNumberResult: {
        countryCode: "FR",
        isValid: true
      },
      modifyCollaborateurGroups: {},

      modifyCollaborateurButtonText: "Modifier votre collaborateur",
      modifyCollaborateurButtonStyle: 'primary',
      collaborateurModificationInProcess: false,

      userSexOptions: [
        { label: 'Monsieur', value: 'H' },
        { label: "Madame", value: 'F' },
      ],
      userFonctionOptions: [
        { label: 'Collaborateur', value: 'COL' },
        { label: "Manager", value: 'MAN' },
        { label: "Associé", value: 'ASS' },
      ],

      // ------------ CHANGE COLLABORATEUR GROUPS ----------
      setUserManagerButtonText: 'Elever au rang de manager',
      setUserManagerButtonStyle: 'outline-success',
      userManagerSettingInProcess: false,
      removeUserManagerButtonText: 'Retirer du groupe manager',
      removeUserManagerButtonStyle: 'outline-danger',
      userManagerRemovingInProcess: false,
      setUserAdminButtonText: "Elever au rang d'administrateur",
      setUserAdminButtonStyle: 'outline-success',
      userAdminSettingInProcess: false,
      removeUserAdminButtonText: 'Retirer du groupe administrateur',
      removeUserAdminButtonStyle: 'outline-danger',
      userAdminRemovingInProcess: false,
      
      setUserBossButtonText: "Elever au rang de super-administrateur",
      setUserBossButtonStyle: 'outline-success',
      userBossSettingInProcess: false,
      removeUserBossButtonText: 'Retirer du groupe super-administrateur',
      removeUserBossButtonStyle: 'outline-danger',
      userBossRemovingInProcess: false,

      // ------------ DESACTIVATE COLLABORATEUR ----------
      isCollaborateurArchived: false,
      isCollaborateurArchiving: false,
      desactivateCollaborateurEmail: '',
      desactivateUserButtonText: 'Désactiver ce collaborateur',
      desactivateUserButtonStyle: 'danger',
      userDesactivatingInProcess: false,

      // ------------ ACTIVATE COLLABORATEUR ----------
      activateUserButtonText: "Réactiver ce collaborateur",
      activateUserButtonStyle: "primary",
      userActivationInProcess: false,
      
      
      monthsArrayForm: [
        { label: 'Janvier', value: '01' },
        { label: 'Février', value: '02' },
        { label: 'Mars', value: '03' },
        { label: 'Avril', value: '04' },
        { label: 'Mai', value: '05' },
        { label: 'Juin', value: '06' },
        { label: 'Juillet', value: '07' },
        { label: 'Août', value: '08' },
        { label: 'Septembre', value: '09' },
        { label: 'Octobre', value: '10' },
        { label: 'Novembre', value: '11' },
        { label: 'Décembre', value: '12' },
      ],



    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isCollaborateurLoading || this.isCollaborateurAllMissionsLoading) {
        return true
      }
      return false
    },
    
    // ---- Date 
    daysArrayForm () {
      var days = []
      for (var i=1; i<=31; i++) {
        var value = String(i)
        if (i <= 9) {
          value = "0" + String(i)
        }
        days.push({'text': value, 'value': value})
      }
      return days
    },
    
    yearsArrayForm () {
      var years = []
      for (var i=1945; i<=2010; i++) {
        var value = String(i)
        years.push({'text': value, 'value': value})
      }
      return years
    },
    
    birthdateFormat () {
      return this.modifyCollaborateurBirthdateYear + '-' + this.modifyCollaborateurBirthdateMonth + '-' + this.modifyCollaborateurBirthdateDay
    }
  },
  validations: {

    modifyCollaborateurEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    modifyCollaborateurFirstName: {
      required,
      maxLength: maxLength(30),
      minLength: minLength(2)
    },
    modifyCollaborateurLastName: {
      required,
      maxLength: maxLength(150),
      minLength: minLength(2)
    },
    modifyCollaborateurSex: {
      required
    },
    modifyCollaborateurFonction: {
      required
    },
    modifyCollaborateurTauxHoraire: {
      required,
      integer,
      minValue: minValue(0),
      maxValue: maxValue(10000)
    },
    birthdateFormat: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyCollaborateurPhoneNumberResult: {
      countryCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          return value
        }
      },
      nationalNumber: {
        required,
        integer
      }
    },

    desactivateCollaborateurEmail: {
      email,
      maxLength: maxLength(149),
      minLength: minLength(2)
    },
  },

  created: function() {
    this.$dayjs.locale('fr')
    this.getCollaborateur()
    this.getCollaborateurAllMissions()
  },

  methods: {
    // ------------- Getters --------------
    getCollaborateur () {
      this.isCollaborateurLoading = true
      apiAdminConnected.getCollaborateur(this.token, this.$route.params.id)
      .then((result) => {
        this.collaborateur = result.data

        this.modifyCollaborateurEmail = this.collaborateur.user.username
        this.modifyCollaborateurFirstName = this.collaborateur.user.first_name
        this.modifyCollaborateurLastName = this.collaborateur.user.last_name
        this.modifyCollaborateurSex = this.collaborateur.sex
        this.modifyCollaborateurFonction = this.collaborateur.fonction
        this.modifyCollaborateurTauxHoraire = this.collaborateur.taux_horaire
        var birthdate = this.$dayjs(this.collaborateur.birthdate, 'YYYY-MM-DD')
        this.modifyCollaborateurBirthdateYear = birthdate.format('YYYY')
        this.modifyCollaborateurBirthdateMonth = birthdate.format('MM')
        this.modifyCollaborateurBirthdateDay = birthdate.format('DD')
        
        this.modifyCollaborateurPhoneNumber = this.collaborateur.phone_number,
        this.modifyCollaborateurPhoneNumberResult = {
          countryCode: this.collaborateur.phone_country_code,
          isValid: true,
          nationalNumber: this.collaborateur.phone_number
        }
        this.modifyCollaborateurGroups = this.collaborateur.user.groups
        this.desactivateCollaborateurEmail = this.collaborateur.inactive_email

      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCollaborateurLoading = false
      })
    },
    
    getCollaborateurAllMissions () {
      this.isCollaborateurAllMissionsLoading = true
      apiAdminConnected.getCollaborateurAllMissions(this.token, this.$route.params.id)
      .then((result) => {
        this.missions = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCollaborateurAllMissionsLoading = false
      })
    },


    // ---------- Modify contact -----------
    setUserManagerStatus () {
      this.setUserManagerButtonText = 'En cours'
      this.setUserManagerButtonStyle = 'secondary'
      this.userManagerSettingInProcess = true
      apiAdminConnected.addUserToManagerGroup(this.token, this.collaborateur.id)
      .then(() => {
        this.getCollaborateur()
        this.isCollaborateurModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.setUserManagerButtonText = 'Elever au rang de manager'
        this.setUserManagerButtonStyle = 'outline-success'
        this.userManagerSettingInProcess = false
      })
    },

    removeUserManagerStatus () {
      this.removeUserManagerButtonText = 'En cours'
      this.removeUserManagerButtonStyle = 'secondary'
      this.userManagerRemovingInProcess = true
      apiAdminConnected.removeUserFromManagerGroup(this.token, this.collaborateur.id)
      .then(() => {
        this.getCollaborateur()
        this.isCollaborateurModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.removeUserManagerButtonText = 'Retirer du groupe manager'
        this.removeUserManagerButtonStyle = 'outline-danger'
        this.userManagerRemovingInProcess = false
      })
    },

    setUserAdminStatus () {
      this.setUserAdminButtonText = 'En cours'
      this.setUserAdminButtonStyle = 'secondary'
      this.userAdminSettingInProcess = true
      apiAdminConnected.addUserToAdminGroup(this.token, this.collaborateur.id)
      .then(() => {
        this.getCollaborateur()
        this.isCollaborateurModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.setUserAdminButtonText = "Elever au rang d'administrateur"
        this.setUserAdminButtonStyle = 'outline-success'
        this.userAdminSettingInProcess = false
      })
    },

    removeUserAdminStatus () {
      this.removeUserAdminButtonText = 'En cours'
      this.removeUserAdminButtonStyle = 'secondary'
      this.userAdminRemovingInProcess = true
      apiAdminConnected.removeUserFromAdminGroup(this.token, this.collaborateur.id)
      .then(() => {
        this.getCollaborateur()
        this.isCollaborateurModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.removeUserAdminButtonText = 'Retirer du groupe administrateur'
        this.removeUserAdminButtonStyle = 'outline-danger'
        this.userAdminRemovingInProcess = false
      })
    },
    
    
    setUserBossStatus () {
      this.setUserBossButtonText = 'En cours'
      this.setUserBossButtonStyle = 'secondary'
      this.userBossSettingInProcess = true
      apiAdminConnected.addUserToBossGroup(this.token, this.collaborateur.id)
      .then(() => {
        this.getCollaborateur()
        this.isCollaborateurModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.setUserBossButtonText = "Elever au rang de super-administrateur"
        this.setUserBossButtonStyle = 'outline-success'
        this.userBossSettingInProcess = false
      })
    },

    removeUserBossStatus () {
      this.removeUserBossButtonText = 'En cours'
      this.removeUserBossButtonStyle = 'secondary'
      this.userBossRemovingInProcess = true
      apiAdminConnected.removeUserFromBossGroup(this.token, this.collaborateur.id)
      .then(() => {
        this.getCollaborateur()
        this.isCollaborateurModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.removeUserBossButtonText = 'Retirer du groupe super-administrateur'
        this.removeUserBossButtonStyle = 'outline-danger'
        this.userBossRemovingInProcess = false
      })
    },

    desactivateUser () {
      this.desactivateUserButtonText = 'En cours'
      this.desactivateUserButtonStyle = 'secondary'
      this.userDesactivatingInProcess = true
      apiAdminConnected.desactivateUser(this.token, this.collaborateur.id, this.desactivateCollaborateurEmail)
      .then(() => {
          this.getCollaborateur()
          this.isCollaborateurArchiving = false
          this.isCollaborateurArchived = true
      })
      .catch(() => {
          this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.desactivateUserButtonText = 'Désactiver cet utilisateur'
        this.desactivateUserButtonStyle = 'danger'
        this.userDesactivatingInProcess = false
      })
    },

    activateUser () {
      this.activateUserButtonText = 'En cours'
      this.activateUserButtonStyle = 'secondary'
      this.userActivationInProcess = true
      apiAdminConnected.activateUser(this.token, this.collaborateur.id)
      .then(() => {
          this.getCollaborateur()
      })
      .catch(() => {
          this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isCollaborateurReactivating = false
        this.activateUserButtonText = 'Réactiver cet utilisateur'
        this.activateUserButtonStyle = 'primary'
        this.userActivationInProcess = false
      })
    },

    modifyCollaborateur () {
      this.modifyCollaborateurButtonText = 'Modification en cours'
      this.modifyCollaborateurButtonStyle = 'secondary'
      this.collaborateurModificationInProcess = true
      apiAdminConnected.modifyCollaborateur(
        this.token, this.collaborateur.id, this.modifyCollaborateurEmail, this.modifyCollaborateurFirstName,
        this.modifyCollaborateurLastName, this.modifyCollaborateurSex, this.modifyCollaborateurFonction,
        this.modifyCollaborateurTauxHoraire, this.birthdateFormat, this.modifyCollaborateurPhoneNumberResult.countryCode,
        this.modifyCollaborateurPhoneNumberResult.nationalNumber
      )
      .then(() => {
        this.getCollaborateur()
        this.isCollaborateurModified = true
      })
      .catch((error) => {
        if (error.response && error.response.data.username && error.response.data.username[0] === "Un utilisateur avec cet email existe déjà") {
          this.isExistingModifyUserEmail = true
        }
        else {
          this.$store.commit('openGlobalErrorModal')
        }
      })
      .finally(() => {
        this.modifyCollaborateurButtonText = "Modifier votre collaborateur"
        this.modifyCollaborateurButtonStyle = 'primary'
        this.collaborateurModificationInProcess = false

      })
    },

    userGroupNotContain(group) {
      var userGroups = this.modifyCollaborateurGroups
      //var userGroupsJson = JSON.parse(userGroups)
      var userGroupsArray = []
      var is_contained = false
      for (var i = 0; i < userGroups.length; i++) {
        userGroupsArray.push(
          userGroups[i]['name']
        )
      }
      if (userGroupsArray.indexOf(group) == -1) {
        is_contained = true
      }
      return is_contained
    },
    
    // ------------ ROUTER FUNCTIONS -------------------
    goToMissionPage(item) {
      this.$router.push('/mission/' + item.id)
    }
  }
}
</script>
